<i18n>
    {
        "es": {
            "LABELS":{
                "MOVEMENT_TYPE":"Tipo de entrada"
            }
        }
    }
</i18n>
<template>
<ModalForm
    id="modalInputEditForm"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
    class="row"
>

    <!-- ALMACÉN Y CUSTOMER-->
    <RowForm :twoColums="true" class="col-md-12" :widht="'none'">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Almacén</label>
            <SelectOption
                class="itemControl"
                name="warehouseId"
                :options="comboWhereHouses"
                :disabled="readOnly"
            />
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">Proveedor</label>
            <SelectOption
                class="itemControl"
                name="supplierId"
                :options="comboCustomers"
                :disabled="readOnly"
                @change="onChangeCustomer"
            />
        </template> 
    </RowForm>
     <div class="col-md-12 col-sm-12 fv-row">
        <label class="required fs-6 fw-bold mb-2">{{ $t("LABELS.MOVEMENT_TYPE") }}</label>
        <SelectOption :name="'movementTypeId'"
            :placeholder="''" 
            :options="movementOptions"
                :disabled="readOnly"
            ></SelectOption>
    </div>
    <!-- OBSERVACIONES -->
    <div class="col-md-12 rowItemControl paddingTop">
        <label class="fs-6 fw-bold mb-2">Observaciones</label>
        <Field  name="observations" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    type="text" 
                    placeholder="Observaciones"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                />
            </el-form-item>
        </Field>
    </div> 

    
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed, onMounted } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../../components/c-modal-form/ModalFormMaster.vue";
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import router from "@/router";
import tableModule from "@/store/modules/werehouse/modules/inputs-table";
import store from "@/store";
//import SelectFilterRemote from "../../../components/forms/SelectFilterRemote.vue"

export default defineComponent({
    components:{
        Field,
        ModalForm,
        SelectOption,
        RowForm,
        //SelectFilterRemote
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nueva Entrada"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let wherehouses = ref([]);
        let customers = ref([]);
        let selectedCustomer = null;
        // let suppliers = ref([]);
        let movementTypes = ref([]);
        // let liquidations = ref([]);
        let returns = ref([]);
        let isCredit = ref(false);
        let purchaseOrder = ref("");
        let lstPurchaseOrders = ref([]);
        let controller = "inputs";
        const validationSchema = Validations.object().shape({
            warehouseId: Validations.string().required().label("Almacén"),
            supplierId: Validations.string().required().label("Id Cliente"),
            supplierName: Validations.string().label("Nombre Cliente")
            , movementTypeId: Validations.string().default('').required()
            , observations: Validations.string().label("Observaciones")
        });

        onBeforeMount(async() => {
            await getComboOptions("warehouses", wherehouses);
            await getComboOptions("Suppliers", customers);
            await getComboOptions("MovementTypes", movementTypes, "combo/inputs");
        });

        watch(currentOpenedItem, (nValue) => {
            if(currentOpenedItem.value) {
                isCredit.value = currentOpenedItem.value.isItACreditEntry;
                if(currentOpenedItem.value.maintenanceServiceFolio != null && currentOpenedItem.value.maintenanceServiceFolio != "") {
                    onRemoteSearchPurchaseOrders(currentOpenedItem.value.maintenanceServiceFolio);
                }
                if(currentOpenedItem.value.relatedMovementId != null) {
                    purchaseOrder.value = currentOpenedItem.value.relatedMovementId;
                } else {
                    purchaseOrder.value = "";
                }
                //console.log(currentOpenedItem.value)
                modalForm.value.$refs.form.setFieldValue("supplierId", currentOpenedItem.value.supplierId); 
                //modalForm.value.$refs.form.setFieldValue("movementTypeId", currentOpenedItem.value.movementTypeId); 
            }
        });

        watch(purchaseOrder, (nValue) => {
            if(nValue && nValue.trim() == "") {
                isCredit.value = false;
                modalForm.value.$refs.form.setFieldValue("IsItACreditEntry", false);
            }
        })

        const initialValues = computed(() => {
            let item = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                item.warehouseId = currentOpenedItem.value.warehouseId;
                item.supplierId = currentOpenedItem.value.supplierId;
                item.movementTypeId = currentOpenedItem.value.movementTypeId;
                item.supplierName = currentOpenedItem.value.supplierName;
                item.observations = currentOpenedItem.value.observations;
            }             
            return item;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const disableCreditCheckbox = computed(() => {
            return !(mode.value != "V" && purchaseOrder.value != "" && purchaseOrder.value != null);
        });

        const comboWhereHouses = computed(() => { return wherehouses.value });
        const comboCustomers = computed(() => { 
            return customers.value.map(c => { return { id: c.id, name: c.name }})
        } );
        const movementOptions = computed(() => { return movementTypes.value });
        //const comboMovementTypes = computed(() => { return movementTypes.value });
        // const getIsCredit = computed(() => { return isCredit.value;});
        // const getComboLiquidations = computed(() => { return liquidations.value; });
        // const getComboPurchaseOrders = computed(() => { return lstPurchaseOrders.value; })

        //FUNCIONES
        const initDefaults = () => {
            modalForm.value.$refs.form.resetForm();           
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const getCurrentRenderMovement = (movementId) => {
            return tableModule.getRecordsTable.find((r) => r.id == movementId);
        }

        const canBackToCI = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return  movement ? movement.status == 1 : false;
        }

        const isEndedCapture = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            if (movement && movement.status !== undefined) {
                return movement.status === 1;
            } else {
                return false;
            }
        };

        /*const isEndedCapture = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return (movement?.status ?? -1) == 1;
        }*/

        const canApprovalWarehouseManager = computed(() => store.getters["canManagerWarehouseApproval"]);

        const showApprovalWarehouseManagerOption = (movementId) => {
            let movement = getCurrentRenderMovement(movementId);
            return movement ? movement['eMovementStatus'] == 1 && !movement['whereHouseManagerApproval']: false;
        }

        const saveNewItem = async(item) => {
            item.supplierName = selectedCustomer.name;
            //item.movementTypeId = '14c144d1-affd-48af-b79f-f8bedd34405d';
            ApiService.post(controller, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    let dataProps = `inputId:${item.id}|movementFolio:${res.data.folio}|requireCI:${canBackToCI(item.id)}|requireCF:${!isEndedCapture(item.id)}|requireAWM:${canApprovalWarehouseManager.value && showApprovalWarehouseManagerOption(item.id)}`;
                    router.push({
                        name: '/warehouse/input/:inputId/details',
                        params: {
                            inputId: btoa(dataProps),
                            movementFolio: res.data.folio
                        }
                    });
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;
            if(selectedCustomer == null) {
                onChangeCustomer(item.supplierId);
            }
            item.supplierName = selectedCustomer.name;
            //item.movementTypeId = '14c144d1-affd-48af-b79f-f8bedd34405d';
            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, method = "combo", parentId = "", criteria = "", fnCallback = null) => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let paramCriteria = criteria.trim() != "" ? `?criteria=${criteria}` : "";
            let url = `${resource}/${parentParam}${method}${paramCriteria}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
                if(fnCallback) {
                    fnCallback();
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeIsCredit = (event) => {
            isCredit.value = event.target.checked;
        }

        const onRemoteSearchReturns = (query) => {
            getComboOptions("returns", returns, "combo", "", query);
        }

        const onRemoteSearchPurchaseOrders = (query) => {
            getComboOptions("PurchaseOrders", lstPurchaseOrders, "combo", "", query.trim().toUpperCase());
        }

        const handleChangePurchaseOrder = (value) => {
            purchaseOrder.value = value;
        }

        const onChangeCustomer = (supplierId) => {
            selectedCustomer = customers.value.find(c => c.Id = supplierId);
        }

        return {
            editForm,
            modalForm,
            validationSchema,

            //Variables computadas
            initialValues,
            comboWhereHouses,
            comboCustomers,
            // comboSupplier,
            // comboMovementTypes,
            // getComboLiquidations,
            // getComboPurchaseOrders,
            // getIsCredit,
            readOnly,
            isEditing,
            disableCreditCheckbox,

            //Funciones
            handleCrudAction,
            onChangeIsCredit,
            onRemoteSearchReturns,
            handleChangePurchaseOrder,
            onRemoteSearchPurchaseOrders,
            initDefaults,
            onChangeCustomer
            , movementOptions
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 250px;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>

